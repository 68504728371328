/* section footer  */
.footer {
  background: url(../../images/bg/bg-footer.svg) no-repeat center right;
  padding: 174px 0 52px;
}

.footer-menu {
  font-size: 14px;
  text-transform: uppercase;
  justify-content: flex-end;
  > a {
    margin-right: 56px;
    color: $text-color;
  }
}

.social-link {
  &__item {
    &:hover {
      color: #fff;
    }
    width: 21px;
    height: 21px;
    font-size: 11px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 6px;
    background-color: #8baeed;
    @include transition(0.5s ease);
  }

  &--facebook:hover {
    background-color: #425ea9;
  }

  &--linkedin:hover {
    background-color: #006eac;
  }

  &--twitter:hover {
    background-color: #0086d0;
  }
}

@media only screen and (max-width: 1300px) {
  .footer {
    background-position: center right -100px;
  }
}

@media only screen and (max-width: 1199.8px) {
  .footer {
    background-position: bottom right -46px;
    background-size: 1560px;
    padding: 160px 0 38px;
  }

  .footer-menu {
    > a {
      margin-right: 50px;
      font-size: 12px;
      line-height: normal;
    }
  }
}

@media only screen and (max-width: 991.8px) {
  .footer {
    background-position: bottom right -26px;
    background-size: 1100px;
    padding: 105px 0 38px;
  }
}

@media only screen and (max-width: 767.8px) {
  .logo__bottom {
    margin: 0 auto;
    display: block;
    width: 110px;
    text-align: center;
  }

  .footer-menu {
    justify-content: space-between;
    margin-bottom: 26px;
    > a {
      margin-right: 22px;
    }
  }

  .footer {
    background-position: bottom right -60px;
    background-size: 1589px;
    padding: 105px 0 38px;
  }
}

@media only screen and (max-width: 425px) {
  .footer {
    background: url(../../images/bg/bg-footer-375.svg) no-repeat bottom right/cover;
    padding: 110px 0 30px;
  }

  .footer-menu {
    flex-direction: column;
    > a {
      margin-bottom: 12px;
      margin-right: 0;
    }
  }

  .social-link {
    margin-top: 20px;
    &__item {
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

@media only screen and (min-width: 1500px) {
  .footer {
    padding: 200px 0 52px;
    background-position: bottom right;
  }
}

@media only screen and (min-width: 1920px) {
  .footer {
    background-position: bottom left 34%;
  }
}

@media only screen and (min-width: 2561px) {
  .footer {
    background-position: bottom center;
  }
}

.terms {
  position: relative;

  &::before{
    content: '';
    width: 100%;
    height: 99px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $base-bg;
  }
  h1 {
    font-size: 26px;
  }

  .wrapper {
    padding: 50px;
    background: #ffffff;
    border-radius: 11px;
    margin-top: 30px;

    section {
      padding-top: 30px;

      h2 {
        font-weight: 600;
        font-size: 21px;
        padding-bottom: 8px;
      }

      p {
        padding-bottom: 8px;
      }
    }
  }
}

@media only screen and (max-width: 767.8px) {
  .terms {
    &::before{
      height: 88px;
    }

    .wrapper {
      padding: 30px 20px;
    }
  }
}

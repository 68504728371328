.page-404 {
  position: relative;
  min-height: calc(100vh - 266px);
  display: flex;
  align-items: center;
  justify-content: center;

  &::before{
    content: '';
    width: 100%;
    height: 99px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $base-bg;
  }

  .image {
    width: 100%;
  }
}

.pagination {
  margin: 40px 0 20px;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-family: $base-font-family;
    font-size: 14px;
    margin-right: 10px;
    color: $text-color;
    @include transition(0.4s);
    z-index: 1;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      @include transition(0.4s);
      z-index: -1;
    }

    &:hover,
    &.active {
      color: $text-white;

      &::before {
        border-color: transparent;
        background-color: $light-blue;
        //@include transform(rotate(45deg));
      }
    }

    &.empty {
      &::before {
        display: none;
      }

      &:hover {
        color: $text-color;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

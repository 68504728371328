/* section creating-resume */
.creat-resume {
  background: url(../../images/bg/bg-section-1.svg) no-repeat top center;
  padding: 65px 0 180px;
  &__title {
    font-size: $font-size-h1;
    line-height: $line-height-h1;
    margin: 62px 0 20px;
  }
  p {
    font-size: 20px;
  }
  &__btn {
    width: 250px;
    height: 45px;
    margin-top: 55px;
    font-size: 16px;
  }
  &__img {
    width: 85%;
    margin: auto;
  }
}

/* section value-resume */
.value-resume {
  position: relative;
  padding: 64px 0 40px;
  background: url(../../images/bg/bg-section-2.svg) no-repeat bottom 130px
    center;
  &__img {
    position: absolute;
    top: -20px;
    right: 0;
  }
  &__title {
    margin-bottom: 110px;
  }
}

.card {
  width: 70%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.21;
  &__img {
    width: 56%;
    margin: 0 auto 70px;
  }
  &__title {
    font-size: $base-font-size;
    line-height: normal;
    color: $text-black;
    margin-bottom: 16px;
  }
}

/* section good-resume */
.good-resume {
  background: url(../../images/bg/bg-section-3.svg) no-repeat top center;
  padding: 117px 0 160px;
  &__title {
    margin-left: 60px;
  }
  &__text {
    margin-bottom: 28px;
    width: 90%;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.slider {
  width: 100%;
  margin-top: 156px;
  position: relative;
  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__item {
    margin: 18px 35px;
    img {
      @include transform(scale(0.8));
      @include transition(transform 0.5s ease);
    }
  }
  .slick-center {
    img {
      @include transform(scale(1));
      box-shadow: 0 2px 16px 0 $light-gray;
    }
  }
  &__next,
  &__prev {
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    font-size: 40px;
    color: $color-arrows;
    z-index: 10;
    &:hover {
      color: $color-arrows-hover;
    }
    svg {
      width: 15px;
    }
  }
  &__prev {
    left: 0;
  }
  &__next {
    right: 0;
  }
}

/* section how-create-cv */
.how-create-cv {
  background: url(../../images/bg/bg-section-4.svg) no-repeat top center;
  padding: 150px 0 70px;
  margin-top: -160px;
  position: relative;
  &__title {
    margin-bottom: 104px;
  }
  &__img {
    margin-left: 50px;
  }
  &__desc {
    width: 100%;
    > p {
      width: 59%;
    }
  }
  &__step {
    margin-left: 90px;
    margin-bottom: -26px;
    display: block;
  }
}

/* section sing-up */
.sing-up {
  position: relative;
  margin-bottom: -90px;
  z-index: 1;
  &__content {
    background: url(../../images/bg/bg-form.svg) no-repeat center left;
    padding: 80px 145px 108px 97px;
    margin-right: 50px;
    color: $text-white;
    justify-content: space-between;
    align-items: center;
    > p {
      font-family: $second-font-family;
      font-size: $font-size-h3;
      font-weight: 500;
      > b {
        display: block;
        font-weight: 700;
      }
    }
  }
  &__btn {
    height: 45px;
    width: 150px;
    margin-left: 24px;
  }
}

/* section eady-creat-resume */
.ready-create-resume {
  margin: 120px 0;
  &__text {
    font-size: 16px;
    margin-top: 16px;
  }
  &__arrow-img {
    position: absolute;
    left: -65%;
    top: 11%;
  }
  &__btn {
    position: relative;
    margin-top: 10px;
    .for-free {
      display: block;
      width: 500px;
      height: 80px;
      background: url("../../images/landing/ready-create-resume/for-free.svg")
        no-repeat center center/cover;
    }
    .creat-resume__btn {
      margin-left: 17%;
      margin-top: -10px;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .creat-resume {
    background-size: cover;
    &__title {
      font-size: $font-size-h1 - 4px;
    }
  }

  .sing-up {
    &__content {
      background-size: contain;
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 1199.8px) {
  .creat-resume {
    padding: 0 0 130px;
    background-size: 1900px;
    background-position: top -20px center;
    &__title {
      font-size: 25px;
      margin: 62px 0 16px;
    }
    p {
      font-size: 14px;
    }
    &__btn {
      width: 210px;
      margin-top: 45px;
      font-size: 14px;
    }
    &__img {
      width: 90%;
    }
  }

  .value-resume {
    background-position: bottom 110px center;
    background-size: 1900px;
    &__title {
      margin-bottom: 80px;
    }
    &__img {
      top: -10px;
      width: 50%;
    }
  }

  .card {
    font-size: 12px;
    line-height: 1.41;
    &__title {
      font-size: 14px;
      margin-bottom: 9px;
    }
    &__img {
      width: 54%;
      margin: 0 auto 34px;
    }
  }

  .good-resume {
    padding: 30px 0 100px;
    background-size: 1800px;
    &__text {
      margin-bottom: 20px;
      font-size: 14px;
    }
  }

  .slider {
    margin-top: 80px;
    &__item {
      margin: 16px 26px;
    }
    &__next,
    &__prev {
      font-size: 30px;
    }
  }

  .how-create-cv {
    padding: 0 0 66px;
    margin-top: 0;
    background-size: 1800px;
    &__title {
      margin-bottom: 60px;
    }
    &__desc {
      font-size: 14px;
      > p {
        width: 70%;
      }
    }
    &__step {
      width: 60%;
    }
    &__content {
      padding: 72px 50px;
      > p {
        font-size: 20px;
      }
    }
  }

  .ready-create-resume {
    margin: 60px 0;
    &__text {
      margin-top: 10px;
      font-size: 14px;
    }
    &__arrow-img {
      left: -60%;
      width: 70%;
    }
    &__btn {
      .for-free {
        width: 400px;
        height: 60px;
      }
      .creat-resume__btn {
        margin-top: -6px;
      }
    }
  }
}

@media only screen and (max-width: 991.8px) {
  .creat-resume {
    padding: 20px 0 70px;
    background-size: 1400px;
    background-position: top center;
    &__img {
      width: 100%;
    }
  }

  .value-resume {
    background-position: bottom 145px center;
    background-size: 1760px;
    &__img {
      top: -5px;
      width: 62%;
    }
  }

  .card {
    width: 82%;
  }

  .good-resume {
    background-size: 1460px;
    &__title {
      margin-left: 0;
    }
    &__text {
      font-size: 12px;
      width: 85%;
    }
  }

  .slider {
    &__item {
      margin: 16px 0;
    }
  }

  .how-create-cv {
    padding: 0 0 66px;
    margin-top: 0;
    background-size: 1330px;
    background-position: top -60px center;
    &__desc {
      > p {
        width: auto;
        margin-left: 52px;
      }
      &_left {
        .how-create-cv__step {
          margin-left: 0;
        }
        > p {
          margin-left: 0;
          margin-right: 52px;
        }
      }
    }
    &__step {
      width: 60%;
      margin-left: auto;
    }
    &__img {
      margin: 0 50px 0 40px;
    }
  }

  .sing-up {
    &__content {
      background: url(../../images/bg/bg-form-tablet.svg) no-repeat center
        center;
      padding: 35px 36px 40px 42px;
      flex-direction: column;
      text-align: center;
      width: 92%;
    }
    &__form {
      margin-top: 30px;
    }
  }

  .ready-create-resume {
    margin: 40px 0;
    &__arrow-img {
      left: -37%;
      top: 27%;
      width: 170px;
    }
    &__btn {
      .for-free {
        width: 312px;
        height: 48px;
      }
      .creat-resume__btn {
        margin-top: -6px;
      }
    }
  }
}

@media only screen and (max-width: 767.8px) {
  .creat-resume {
    padding: 26px 0 66px;
    background-size: 1100px;
    &__title {
      font-size: 20px;
      margin: 0px 0 8px;
      text-align: center;
    }
    p {
      font-size: 12px;
      text-align: center;
    }
    &__btn {
      width: 200px;
      margin: 32px auto 0;
    }
  }

  .value-resume {
    background-size: 0;
    padding: 64px 0 0;
    &__title {
      margin-bottom: 60px;
    }
    &__img {
      top: 10px;
      width: 70%;
    }
  }

  .card {
    width: 83.2%;
    min-width: 290px;
    margin-bottom: 60px;
    &__img {
      width: 120px;
      margin: 0 auto 30px;
    }
  }

  .good-resume {
    padding: 30px 0 80px;
    background-size: 1000px;
    &__title {
      text-align: center;
      margin-bottom: 36px;
    }
    &__text {
      font-size: 12px;
      width: 83.2%;
      margin: 0 auto 16px;
    }
  }

  .slider {
    margin-top: 24px;
    &__item {
      margin: 20px 24px;
      > img {
        @include transform(scale(1));
        box-shadow: 0 2px 16px 0 $light-gray;
      }
    }
  }

  .how-create-cv {
    &__title {
      margin-bottom: 36px;
    }
    &__img {
      margin: 0 auto 30px;
      width: 240px;
    }
    &__desc {
      > p {
        margin-left: 29px;
        width: 83.2%;
        min-width: 290px;
        margin: 0 auto;
      }
    }
    &__step {
      margin-bottom: -20px;
    }
  }

  .sing-up {
    &__content {
      background: url(../../images/bg/bg-form-375.svg) no-repeat center
        center/contain;
      padding: 56px 28px 40px 40px;
      width: 100%;
      > p {
        font-size: 16px;
      }
    }
    &__form {
      margin-top: 30px;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    &__btn {
      height: 35px;
      width: 120px;
      margin: 24px auto 0;
    }
  }

  .ready-create-resume {
    margin: 30px 0;
    &__title {
      text-align: center;
    }
    &__text {
      width: 84%;
      font-size: 12px;
      text-align: center;
      margin: 14px auto;
    }
    &__arrow-img {
      display: none;
    }
    &__btn {
      .for-free {
        width: 260px;
        height: 40px;
        margin: 0 auto;
      }
      .creat-resume__btn {
        margin: -6px auto 0;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .creat-resume {
    padding: 36px 0 66px;
    background: url(../../images/bg/bg-section-1-mob.svg) no-repeat top center;
    background-size: 426px;
  }

  .good-resume {
    background: url(../../images/bg/bg-section-3-mob-aa.svg),
      url(../../images/bg/bg-section-3-mob.svg);
    background-repeat: no-repeat;
    background-position: top left, top 146px right;
    padding: 80px 0;
  }
}

@media only screen and (max-width: 320px) {
  .ready-create-resume {
    &__text {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 1301px) {
  .container {
    max-width: 1220px;
    padding: 0 10px;
  }
}

@media only screen and (min-width: 1500px) {
  .value-resume {
    &__img {
      right: 3%;
    }
  }

  .sing-up {
    margin-bottom: -110px;
  }
}

@media only screen and (min-width: 1920px) {
  .creat-resume {
    padding: 65px 0 270px;
  }

  .value-resume {
    margin-top: -100px;
    &__img {
      right: 12%;
    }
  }
}

@media only screen and (min-width: 2561px) {
  .creat-resume {
    padding: 90px 0 540px;
    background-size: cover;
  }

  .value-resume {
    background-position: bottom 76px center;
    background-size: contain;
    margin-top: -190px;
    &__img {
      right: 26%;
    }
  }

  .good-resume {
    background-size: contain;
  }

  .how-create-cv {
    padding: 390px 0 300px;
    background-size: cover;
    margin-top: -340px;
  }
}

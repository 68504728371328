[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-new:before {
  content: "\e914";
}
.icon-remove-new:before {
  content: "\e915";
}
.icon-drag-and-drop:before {
  content: "\e916";
}
.icon-underline:before {
  content: "\e900";
}
.icon-ol-list:before {
  content: "\e901";
}
.icon-ul-list:before {
  content: "\e902";
}
.icon-italic:before {
  content: "\e903";
}
.icon-bold:before {
  content: "\e904";
}
.icon-remove:before {
  content: "\e905";
}
.icon-menu:before {
  content: "\e906";
}
.icon-add:before {
  content: "\e907";
}
.icon-camera:before {
  content: "\e908";
}
.icon-template-facebook:before {
  content: "\e909";
}
.icon-template-linkedin:before {
  content: "\e90a";
}
.icon-template-website:before {
  content: "\e90b";
}
.icon-template-email:before {
  content: "\e90c";
}
.icon-template-address:before {
  content: "\e90d";
}
.icon-template-home:before {
  content: "\e90e";
}
.icon-template-phone:before {
  content: "\e90f";
}
.icon-template-skype:before {
  content: "\e910";
}
.icon-facebook:before {
  content: "\e911";
}
.icon-twitter:before {
  content: "\e912";
}
.icon-linkedin:before {
  content: "\e913";
}
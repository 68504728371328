.template-simple {
  color: #333;
  line-height: 1.6;
  font-family: Tahoma, Verdana, Segoe, sans-serif;

  .page {
    background-color: $base-bg;
    padding: 40px 50px;
  }

  .add-item {
    .icon {
      font-size: 12px;
    }
  }

  .title {
    font-size: 1.2em;
    font-weight: 400;

    .underline {
      display: block;
      background-color: #ff7235;
      width: 70px;
      height: 2px;
      line-height: 18px;
      margin-top: 5px;
    }
  }

  .bio {
    .round {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      background-color: #a3bff7;
      position: absolute;
      opacity: 0.5;
    }

    .name {
      font-size: 2.5em;
      font-weight: 700;
      position: relative;
      padding: 24px 0 0 58px;
      line-height: 40px;
    }

    .position {
      font-weight: 200;
      position: relative;
      padding: 0 0 0 58px;
      font-size: 1.3em;
    }
  }

  .contacts {
    > div {
      padding: 0 30px 10px 15px;
      display: inline-block;
    }

    word-break: break-all;
    width: 560px;
    margin-top: 25px;
    float: right;
    border-top: 1px solid #a3bff7;
    padding: 10px 0;
  }

  .main {
    display: flex;
    margin-top: 40px;
  }

  .address {
    vertical-align: top;
    margin-right: 30px;
    flex: 0 0 130px;

    .value {
      padding-top: 30px;
    }
  }

  .profile {
    vertical-align: top;
    width: 100%;

    .details {
      padding-top: 30px;
      width: 100%;
      &.enabled-photo {
        .text-editor {
          width: 100%;
        }
      }
      .text-editor {
        float: right;
        width: 450px;
      }
    }

    .logo {
      position: relative;
      float: left;
      margin: 0 40px 15px 0;
      min-width: 122px;
      width: 122px;
      height: 122px;
      border-radius: 50%;
      border: 1px solid #a3bff7;
      overflow: hidden;

      .image-wrapper {
        position: absolute;
        top: 0;
        z-index: 1;

        img {
          width: 100%;
        }
      }

      .file-btn {
        @extend %logo-hover;
      }
    }

    .description {
       &[contenteditable='true']:empty:before {
        display: inline-block;
      }
    }
  }

  .skills {
    &.default-section {
      .items {
        .item {
          margin-bottom: 0;
          margin-right: 10px;
          display: inline-block;

          .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            .skill {
              padding: 5px;
            }

            .dot {
              cursor: move;
              padding: 8px 2px 8px 0;
              display: block;

              &:before {
                content: '';
                width: 6px;
                border-radius: 50%;
                height: 6px;
                background-color: #bfbfbf;
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .certification {
    .name {
      font-size: 1.2em;
      padding-bottom: 2px;
    }

    .issuer {
      font-size: 1em;
    }

    .date {
      font-size: 1em;
    }

    .identifier {
      font-size: 1em;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .line {
    display: block;
    margin: 25px 0 25px 0;
    width: 620px;
    line-height: 0;
    float: right;

    div {
      height: 2px;
      display: inline-block;
    }

    .first-color {
      width: 31%;
      background-color: #ff7235;
    }

    .second-color {
      width: 69%;
      background-color: #a3bff7;
    }
  }

  .default-section {
    @extend %main-section;

    .last-item .data {
      padding-top: 0.1px;
    }

    .item:not(:last-of-type) {
      .data {
        padding-top: 0;
      }

      margin-bottom: 15px;
    }

    &.education {
      .university {
        font-size: 1.3em;
        float: left;
        padding-bottom: 5px;
      }

      .field-of-study {
        font-size: 1.1em;
      }

      .item {
        .first-line {
          width: 100%;
        }

        .date {
          font-size: 1.2em;
          float: right;

          > div {
            display: inline-block;
          }
        }
      }
    }

    &.experience {
      .company {
        font-size: 1.3em;
        float: left;
      }

      .position {
        font-size: 1.1em;
        display: inline-block;
      }

      .location {
        font-size: 1em;
        display: inline-block;
        margin-left: 10px;
        color: #7d7d7d;
      }

      .item {
        .description {
          .gap {
            line-height: 0.7;
          }
          padding: 5px 0 0 0;
        }

        .first-line {
          width: 100%;
        }

        .date {
          font-size: 1.2em;
          float: right;

          > div {
            display: inline-block;
          }
        }
      }
    }
  }
}

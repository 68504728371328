.resume-builder {
  overflow: hidden;
  &__header {
    margin: 12px 0 132px;
  }
  &__header-title {
    font-size: 56px;
    line-height: 56px;
    letter-spacing: 0;
    color: #46454a;
    margin: 43px 0 22px;
  }
  &__header-text {
    font-size: 20px;
    line-height: 24px;
    color: #3d3d3d;
  }
  &__btn {
    margin-top: 94px;
  }
  &__header-img {
    width: max-content;
    margin-left: -21px;
  }
  &__info {
    background-color: #fff1eb;
    padding: 123px 0 90px;
    &_title {
      color: #46454a;
    }
    &_text {
      color: #46454a;
    }
  }
  .how-get-start {
    margin: 183px 0 134px;
    font-size: 16px;
    &__title {
      margin-top: 16px;
      font-size: 32px;
      color: #46454a;
    }
    &__sub-title {
      color: #46454a;
      font-family: "Futura", sans-serif;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 31px;
      margin-bottom: 16px;
    }
    p {
      color: #46454a;
      line-height: 19px;
    }
    &__step-1 {
      position: relative;
      border-radius: 40px;
      background-color: rgba(232, 237, 249, 0.5);
      padding: 90px 56px 58px;
      margin-top: 30px;
      .arrow-img {
        position: absolute;
        left: -95%;
        top: 75%;
      }
      &_img {
        position: absolute;
        left: -110px;
        top: -42px;
        width: 520px;
      }
    }
    &__step-2 {
      position: relative;
      border-radius: 24px;
      background-color: #ffffff;
      box-shadow: 0 20px 60px 0 rgba(237, 238, 240, 0.5);
      margin-top: 128px;
      padding: 160px 56px 56px;
      .arrow-img {
        position: absolute;
        right: -23%;
        top: 78%;
      }
      &_img {
        position: absolute;
        left: -113px;
        top: 35px;
        width: 1245px;
      }
    }
    &__step-3 {
      position: relative;
      border-radius: 24px;
      background-color: #f3f6fc;
      margin-top: 192px;
      padding: 221px 100px 70px 56px;
      .arrow-img {
        position: absolute;
        left: 13%;
        top: 114%;
      }
      &_img {
        position: absolute;
        left: 24px;
        top: 42px;
        width: 670px;
      }
    }
    &__step-4 {
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 24px;
      background-color: #ffffff;
      box-shadow: 0 20px 60px 0 rgba(237, 238, 240, 0.5);
      margin-top: 168px;
      padding: 40px 56px 40px 40px;
      .arrow-img {
        position: absolute;
        left: 13%;
        top: 124%;
      }
      &_img {
        margin-right: 56px;
        min-width: 381px;
      }
    }
    &__step-5 {
      position: relative;
      border-radius: 40px;
      background-color: rgba(232, 237, 249, 0.5);
      margin-top: 200px;
      padding: 58px 68px 82px 261px;
      .arrow-img {
        position: absolute;
        right: -14%;
        top: 121%;
      }
      &_img {
        position: absolute;
        left: 0;
        top: -78px;
        width: 258px;
      }
    }
    &__step-6 {
      position: relative;
      border-radius: 24px;
      background-color: #ffffff;
      box-shadow: 0 20px 60px 0 rgba(237, 238, 240, 0.5);
      margin-top: 286px;
      padding: 56px 76px 69px 468px;
      .arrow-img {
        position: absolute;
        right: 9%;
        top: 133%;
      }
      &_img {
        position: absolute;
        left: -132px;
        top: -57px;
        width: 541px;
      }
    }
    &__step-7 {
      border-radius: 24px;
      background-color: #f3f6fc;
      margin-top: 361px;
      padding: 56px 57px;
      p {
        width: 50%;
      }
      &_list {
        width: 45%;
        margin-top: 48px;
        li {
          position: relative;
          margin-bottom: 20px;
          padding-left: 48px;
          line-height: 19px;
          &:last-of-type {
            margin-bottom: 0;
          }
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 32px;
            height: 28px;
            background: url("../../images/resume-builder/icon-checked.svg")
              no-repeat center center/cover;
          }
        }
      }
    }
    &__step-8 {
      position: relative;
      border-radius: 24px;
      background-color: #ffffff;
      box-shadow: 0 20px 60px 0 rgba(237, 238, 240, 0.5);
      margin-top: 260px;
      padding: 56px 264px 70px 56px;
      .arrow-img {
        position: absolute;
        right: -65%;
        top: -65%;
      }
      &_img {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(42%, -16%);
        width: 302px;
      }
    }
    &__step-9 {
      position: relative;
      border-radius: 24px;
      background-color: #f3f6fc;
      margin-top: 303px;
      padding: 160px 80px 56px 56px;
      .arrow-img {
        position: absolute;
        left: -39%;
        top: -83%;
      }
      &_img {
        position: absolute;
        left: -64px;
        top: 33px;
        width: 428px;
      }
    }
  }
  &__benefits {
    background-color: #fff1eb;
    padding: 120px 0;
    &_title {
      font-size: 40px;
      line-height: 40px;
    }
    &_text {
      font-size: 16px;
      margin-top: 16px;
      line-height: 19px;
    }
    &_btn {
      margin-top: 64px;
    }
    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 56px;
      margin-right: 24px;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: inset 2px 2px 3px 0 rgba(247, 216, 202, 0.4),
        0 16px 32px 0 rgba(243, 114, 57, 0.2);
      img {
        width: 32px;
      }
    }
    &_list {
      font-size: 20px;
      line-height: 24px;
      list-style: none;
    }
    &_list-item {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &__creat-resume {
    margin: 160px 0;
    &_title {
      margin-top: 7px;
      color: #3d3d3d;
    }
    &_text {
      color: #3d3d3d;
      font-size: 16px;
      font-weight: 300;
      line-height: 19px;
      margin-top: 16px;
    }
    .arrow-img {
      position: absolute;
      left: -65%;
      top: 12%;
    }
    &_btn {
      position: relative;
      .for-free {
        display: block;
        width: 500px;
        height: 80px;
        background: url("../../images/resume-builder/for-free.svg") no-repeat
          center center/cover;
      }
      .creat-resume__btn {
        margin-left: 17%;
        margin-top: -10px;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .resume-builder {
    &__benefits {
      &_title {
        font-size: 34px;
      }
    }
  }
}

@media only screen and (max-width: 1199.8px) {
  .resume-builder {
    &__header {
      margin: 0 0 82px;
    }
    &__header-title {
      font-size: 25px;
      line-height: normal;
      margin-bottom: 16px;
    }
    &__header-text {
      font-size: 16px;
    }
    &__btn {
      margin-top: 45px;
    }
    &__header-img {
      width: 100%;
      margin-left: 0;
    }
    &__info {
      padding: 80px 0 60px;
      &_text {
        font-size: 16px;
      }
    }
    .how-get-start {
      margin: 90px 0;
      &__title {
        margin-top: 12px;
        font-size: 24px;
      }
      &__sub-title {
        font-size: 18px;
        line-height: normal;
        margin-bottom: 12px;
      }
      &__step-1 {
        padding: 60px 36px 32px;
        margin-top: 20px;
        .arrow-img {
          width: 300px;
          left: -90%;
        }
        &_img {
          width: 414px;
          left: -74px;
          top: -20px;
        }
      }
      &__step-2 {
        margin-top: 90px;
        padding: 90px 36px 36px;
        .arrow-img {
          right: -14%;
          top: 64%;
          width: 260px;
        }
        &_img {
          width: 800px;
          left: -74px;
          top: 20px;
        }
      }
      &__step-3 {
        margin-top: 142px;
        padding: 160px 36px 36px;
        .arrow-img {
          top: 106%;
          left: 17%;
          width: 60px;
        }
        &_img {
          width: 540px;
          left: 36px;
          top: 28px;
        }
      }
      &__step-4 {
        margin-top: 90px;
        padding: 30px 46px;
        .arrow-img {
          left: 13%;
          top: 114%;
          width: 126px;
        }
        &_img {
          min-width: 250px;
          margin-right: 26px;
        }
      }
      &__step-5 {
        margin-top: 120px;
        padding: 38px 48px 38px 200px;
        .arrow-img {
          right: -14%;
          top: 110%;
          width: 160px;
        }
        &_img {
          left: -26px;
          top: -110px;
        }
      }
      &__step-6 {
        margin-top: 160px;
        padding: 36px 46px 50px 340px;
        .arrow-img {
          width: 120px;
          top: 110%;
        }
        &_img {
          width: 440px;
        }
      }
      &__step-7 {
        margin-top: 220px;
        padding: 36px 42px;
        &_list {
          margin-top: 34px;
          li {
            margin-bottom: 16px;
            padding-left: 38px;
            &::before {
              width: 28px;
              height: 24px;
            }
          }
        }
      }
      &__step-8 {
        margin-top: 160px;
        padding: 36px 164px 40px 36px;
        .arrow-img {
          width: 160px;
          right: -60%;
          top: -90%;
        }
        &_img {
          width: 240px;
          transform: translate(45%, -27%);
        }
      }
      &__step-9 {
        margin-top: 160px;
        padding: 90px 40px 46px 40px;
        .arrow-img {
          width: 120px;
          top: -56%;
          left: -26%;
        }
        &_img {
          left: -48px;
          top: 10px;
          width: 360px;
        }
      }
    }
    &__benefits {
      padding: 80px 0;
      &_title {
        font-size: 24px;
        line-height: 1.5;
      }
      &_btn {
        margin-top: 34px;
      }
      &_icon {
        width: 46px;
        height: 46px;
        margin-right: 18px;
        img {
          width: 28px;
        }
      }
      &_list {
        font-size: 16px;
        line-height: normal;
      }
      &_list-item {
        margin-bottom: 22px;
      }
    }
    &__creat-resume {
      margin: 60px 0;
      .arrow-img {
        left: -60%;
        width: 70%;
      }
      &_btn {
        .for-free {
          width: 400px;
          height: 60px;
        }
        .creat-resume__btn {
          margin-top: -6px;
        }
      }
    }
  }
}

@media only screen and (max-width: 991.8px) {
  .resume-builder {
    &__header {
      margin: 0 0 30px;
    }
    &__header-text {
      font-size: 14px;
    }
    &__info {
      padding: 40px 0;
      &_text {
        font-size: 14px;
      }
    }
    .how-get-start {
      margin: 50px 0;
      &__title {
        font-size: 20px;
      }
      &__sub-title {
        font-size: 15px;
      }
      p {
        font-size: 14px;
      }
      &__step-1 {
        margin-top: 20px;
        .arrow-img {
          width: 286px;
          left: -76%;
        }
        &_img {
          width: 340px;
          left: -30px;
          top: 0px;
        }
      }
      &__step-2 {
        margin-top: 60px;
        .arrow-img {
          right: -12%;
          width: 200px;
        }
        &_img {
          width: 760px;
          left: -50px;
        }
      }
      &__step-3 {
        margin-top: 90px;
        padding: 120px 36px 36px;
        .arrow-img {
          top: 110%;
          width: 46px;
          left: 7%;
        }
        &_img {
          width: 512px;
          left: 10px;
          top: 10px;
        }
      }
      &__step-4 {
        margin-top: 70px;
        padding: 30px 20px;
        .arrow-img {
          left: 6%;
          width: 90px;
        }
        &_img {
          min-width: 220px;
        }
      }
      &__step-5 {
        margin-top: 90px;
        padding: 38px 28px 38px 160px;
        .arrow-img {
          right: -4%;
          top: 112%;
          width: 98px;
        }
        &_img {
          top: -90px;
          width: 210px;
        }
      }
      &__step-6 {
        margin-top: 90px;
        padding: 32px 26px 32px 290px;
        .arrow-img {
          width: 80px;
        }
        &_img {
          left: -50px;
          top: -30px;
          width: 320px;
        }
      }
      &__step-7 {
        margin-top: 150px;
        padding: 26px 32px;
        &_list {
          font-size: 14px;
          margin-top: 24px;
          li {
            margin-bottom: 16px;
            padding-left: 38px;
            &::before {
              width: 28px;
              height: 24px;
            }
          }
        }
      }
      &__step-8 {
        margin-top: 120px;
        padding: 26px 124px 20px 26px;
        .arrow-img {
          width: 92px;
          right: -38%;
        }
        &_img {
          width: 200px;
        }
      }
      &__step-9 {
        margin-top: 120px;
        padding: 80px 32px 26px 32px;
        .arrow-img {
          width: 80px;
          top: -54%;
          left: -17%;
        }
        &_img {
          left: -48px;
          top: 10px;
          width: 300px;
        }
      }
    }
    &__benefits {
      padding: 40px 0;
      &_title {
        font-size: 20px;
      }
      &_icon {
        width: 36px;
        height: 36px;
        margin-right: 14px;
        img {
          width: 24px;
        }
      }
      &_list {
        font-size: 14px;
      }
      &_list-item {
        margin-bottom: 18px;
      }
    }
    &__creat-resume {
      margin: 40px 0;
      &_text {
        font-size: 14px;
        line-height: normal;
      }
      .arrow-img {
        left: -40%;
        top: 30%;
        width: 170px;
      }
      &_btn {
        .for-free {
          width: 312px;
          height: 48px;
        }
        .creat-resume__btn {
          margin-top: -6px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767.8px) {
  .resume-builder {
    &__header-title {
      text-align: center;
      margin-top: 16px;
      font-size: 20px;
    }
    &__header-text {
      text-align: center;
      font-size: 12px;
    }
    &__btn {
      margin-top: 32px;
    }
    &__info {
      padding: 20px 0;
      &_title {
        text-align: center;
        margin-bottom: 12px;
        font-size: 16px;
      }
      &_text {
        text-align: center;
        font-size: 12px;
      }
    }
    .how-get-start {
      margin: 50px 0;
      &__title {
        text-align: center;
        font-size: 16px;
      }
      &__sub-title {
        font-size: 14px;
        margin-bottom: 10px;
      }
      p {
        font-size: 12px;
      }
      &__step-1 {
        margin-top: 30px;
        padding: 50px 20px 26px;
        .arrow-img {
          display: none;
        }
        &_img {
          width: 300px;
          left: 5px;
          top: -15px;
        }
      }
      &__step-2 {
        margin-top: 50px;
        padding: 70px 20px 26px;
        .arrow-img {
          display: none;
        }
        &_img {
          width: 600px;
          top: 15px;
          left: -10px;
        }
      }
      &__step-3 {
        margin-top: 90px;
        padding: 100px 90px 26px 20px;
        .arrow-img {
          display: none;
        }
        &_img {
          width: 356px;
        }
      }
      &__step-4 {
        display: block;
        padding: 26px 20px;
        .arrow-img {
          display: none;
        }
        &_img {
          margin: 0 auto 18px;
          width: 240px;
        }
      }
      &__step-5 {
        margin-top: 90px;
        padding: 28px 20px 28px 126px;
        .arrow-img {
          display: none;
        }
        &_img {
          top: -80px;
          width: 180px;
        }
      }
      &__step-6 {
        margin-top: 110px;
        padding: 160px 20px 26px;
        .arrow-img {
          display: none;
        }
        &_img {
          left: 54px;
          top: -66px;
          width: 290px;
        }
      }
      &__step-7 {
        margin-top: 60px;
        padding: 26px 20px;
        p {
          width: 100%;
        }
        &_list {
          font-size: 12px;
          margin-top: 18px;
          width: 100%;
          li {
            margin-bottom: 10px;
            padding-left: 30px;
            &::before {
              width: 22px;
              height: 18px;
            }
          }
        }
      }
      &__step-8 {
        margin-top: 100px;
        padding: 26px 114px 26px 20px;
        .arrow-img {
          display: none;
        }
        &_img {
          width: 140px;
          transform: translate(30%, -25%);
        }
      }
      &__step-9 {
        margin-top: 90px;
        padding: 70px 20px 26px;
        .arrow-img {
          display: none;
        }
        &_img {
          left: 10px;
          top: 6px;
          width: 260px;
        }
      }
    }
    &__benefits {
      padding: 30px 0;
      &_title {
        text-align: center;
        font-size: 16px;
      }
      &_text {
        font-size: 12px;
        text-align: center;
      }
      &_btn {
        margin: 36px auto;
      }
      &_icon {
        min-width: 32px;
        width: 32px;
        height: 32px;
        margin-right: 14px;
        img {
          width: 20px;
        }
      }
      &_list {
        font-size: 12px;
      }
      &_list-item {
        margin-bottom: 18px;
      }
    }
    &__creat-resume {
      margin: 30px 0;
      &_title {
        text-align: center;
      }
      &_text {
        font-size: 12px;
        text-align: center;
        margin-bottom: 14px;
      }
      .arrow-img {
        display: none;
      }
      &_btn {
        .for-free {
          width: 260px;
          height: 40px;
          margin: 0 auto;
        }
        .creat-resume__btn {
          margin: -6px auto 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .resume-builder {
    .container {
      max-width: 520px;
    }
  }
}

body {
  margin: 0;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: 300;
  line-height: $base-line-height;
  color: $text-color;
  background-color: $base-bg;
}

main {
  padding-top: 128px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.root {
  background-color: #f5f7fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &.liner-gradient {
    background-image: -o-linear-gradient(320deg, #e4e8f1, #f6f8fb, #fdfdfd);
    background-image: linear-gradient(130deg, #e4e8f1, #f6f8fb, #fdfdfd);
  }
  &.bg-white {
    background-color: $base-bg;
  }
}

.overflow {
  overflow: hidden;
}

.wow {
  visibility: hidden;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

%btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  @include border-radius(24px);
  @include transition(0.6s ease);
}

.btn-orange {
  @extend %btn;
  min-width: 150px;
  color: $text-white;
  box-shadow: 0 2px 10px 0 $shadow-btn-orang;
  background-color: $orange;
  &:hover {
    opacity: 0.8;
    color: $text-white;
  }
}

.btn-grey {
  @extend %btn;
  min-width: 150px;
  color: $text-white;
  box-shadow: 0 2px 10px 0 rgba(125, 125, 125, 0.52);
  background-color: #a29e9b;
  &:hover {
    opacity: 0.8;
    color: $text-white;
  }
}

.btn-border-orange {
  @extend %btn;
  display: inline-flex;
  padding: 0 16px;
  color: $black;
  border: 1px solid $orange;
  &:hover {
    box-shadow: 0 2px 10px 0 $shadow-btn-orang;
  }
}

.title-section {
  font-family: $second-font-family;
  font-size: $font-size-h2;
  font-weight: 700;
  line-height: $line-height-h2;
  color: $title-color;
  text-transform: uppercase;
  position: relative;
  > span {
    font-weight: 500;
    display: block;
  }
}

.text-center {
  text-align: center;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-150 {
  margin-bottom: 150px;
}

.input-style {
  background-color: transparent;
  border: 1px solid $white;
  height: 45px;
  min-width: 350px;
  padding: 0 22px;
  color: $text-white;
  font-size: $base-font-size;
  &::placeholder {
    font-size: 14px;
    text-transform: uppercase;
    color: $text-white;
  }
}

.lazy__preloader {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 1199.8px) {
  main {
    padding-top: 110px;
  }
  .btn-orange {
    height: 40px;
    font-size: 14px;
  }

  .title-section {
    font-size: 20px;
    line-height: 1.25;
  }

  .mb-150 {
    margin-bottom: 90px;
  }

  .input-style {
    height: 40px;
    min-width: 320px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 991.8px) {
  .mb-150 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767.8px) {
  .btn-orange {
    font-size: 12px;
  }

  .title-section {
    font-size: 16px;
    line-height: 1.5;
  }

  .input-style {
    height: 40px;
    min-width: 260px;
    font-size: 16px;
  }
}

.admin-page {
  .ant-layout-sider-children > .logo {
    padding: 15px 0;
    display: flex;
    justify-content: center;
    img {
      width: 20px
    }
  }

  .mobile {
    &.ant-layout-sider {
      width: 30px !important;
      max-width: 30px !important;
      min-width: 30px !important;
      flex: 0 0 30px !important;

      .ant-layout-sider-children >.logo,
      .ant-layout-sider-trigger {
        display: none;
      }

      .ant-menu {
        width: 30px;
      }
    }

  }

  .users-section {
    .field__name img,
    .field__name svg {
      width: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .ant-menu-inline {
    border-right: 0;
  }

  .template__wrapper {
    display: block;
    height: 620px;
  }

  .ant-table-wrapper.mobile {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 5px 10px;
    }

    .ant-table-cell {
      &.name {
        width: 70px;
      }

      &.email {
        width: 70px;
      }

      &.action {
        width: 65px;
      }
    }

    .template__wrapper {
      display: block;
      height: 420px;

      .template {
        margin: 0 auto;
        transform: scale(0.33);
        transform-origin: left top;
      }
    }

    td.ant-table-row-expand-icon-cell,
    th.ant-table-row-expand-icon-cell,
    .ant-table-expand-icon-col {
      width: 1px;
      padding: 0;
    }

    .ant-table-cell.action {
      width: 50px;
    }
  }

  .ant-table {
    tr:not(.ant-table-expanded-row) td {
      cursor: pointer;
    }
    .ant-table-cell.hide {
      display: none;
    }
  }

  .template {
    margin: 0 auto;
    transform: scale(0.5);
    transform-origin: center top;

    .page {
      margin: 0 auto;
    }
  }

  .less-border.ant-btn {
    border-color: #fff;
    &:hover {
      border-color: #40a9ff;
    }
  }

  .ant-layout-sider {
    background-color: #fff;
  }

  .ant-layout.site-layout{
    margin-top: 55px;
  }

  .ant-menu,
  .ant-layout-sider-children {
    background-color: #ffffff;
  }

  .ant-layout-sider-trigger {
    background: #949494;
  }

  main {
    padding: 0;
  }
}

.admin__section {
  min-height: 100vh;
  padding: 15px 0 20px;

  .title-section {
    text-align: center;
    padding: 30px 0;
  }

  .form {
    width: 600px;
    position: relative;
    margin: 0 auto;
    padding: 50px;
    border: 1px solid #ececec;
    border-radius: 21px;
    background: #fff;

    .main-error {
      text-align: center;
      color: $color-error;
      display: block;
      font-size: 14px;
      margin: 0 auto 30px;
    }

    .form-row {
      margin-bottom: 14px;
    }

    input {
      &:focus {
        border: 1px solid $border-focus;
      }

      &::placeholder {
        color: $color-placeholder;
      }

      &.has-error {
        border: 1px solid $color-error;
      }

      width: 100%;
      color: #1a2a3c;
      background-color: #fff;
      font-size: 15px;
      line-height: normal;
      border: 1px solid #e6e9ec;
      height: 45px;
      padding: 0 20px;
      @include border-radius(30px);
    }

    .login-btn {
      margin: 0 auto;
    }
  }
}

.admin__resume-list {
  background-image: linear-gradient(130deg, #e4e8f1, #f6f8fb, #fdfdfd);
  min-height: 100vh;
  height: 100%;

  .search-box {
    width: 100%;

    &__title {
      text-align: center;
      border-radius: 5px 5px 0 0;
      background-color: #6c7ae0;
      padding: 19px 0;
      font-size: 17px;
      color: #fff;
      line-height: 1.2;
      font-weight: 600;
    }

    &__form {
      padding: 22px 18px;
      background-color: #ffffff;
    }

    &__row {
      margin-bottom: 12px;

      &:last-of-type {
        margin-bottom: 0;
      }

      label {
        display: block;
        font-size: 16px;
        padding: 0 0 2px 15px;
      }

      .button {
        width: 100%;
        min-width: inherit;
        border: none;
        margin-top: 20px;
      }

      input[type="text"] {
        width: 100%;
        height: 40px;
        padding: 0 15px;
        @include border-radius(30px);
        color: #1a2a3c;
        background-color: #fff;
        font-size: 15px;
        line-height: normal;
        border: 1px solid #e6e9ec;
      }
    }
  }

  main {
    padding-top: 0;
  }

  .wrap-table {
    min-height: calc(100vh - 120px);
    overflow: hidden;

    .table {
      width: 100%;
      margin: 0;

      &__row {
        display: flex;
        background: #fff;
        border-bottom: 1px solid #f2f2f2;
        font-size: 15px;
        color: #666;
        line-height: 1.2;
        padding: 16px 0;

        &.header {
          color: #fff;
          @include border-radius(5px 5px 0 0);
          background-color: #6c7ae0;
          font-weight: 600;
          font-size: 17px;
          padding: 19px 0;

          &:hover {
            background-color: #6c7ae0;
          }
        }

        &.active {
          background-color: #dadaed;
        }

        &:hover {
          background-color: #ececff;
          cursor: pointer;
        }

        .cell {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &__col-4 {
        flex: 0 0 26%;
        padding: 0 15px;
      }

      &__col-3 {
        flex: 0 0 18%;
        padding-right: 15px;
      }

      &__col-2 {
        flex: 0 0 12%;
        padding-right: 15px;
      }

      &__col-1 {
        flex: 0 0 8%;
        padding-right: 15px;
        text-align: center;
      }
    }
  }

  .template .page {
    margin: 20px auto;
  }
}

@media only screen and (max-width: 1199.8px) {
  .admin__resume-list {
    .wrap-table {
      .table {
        &__row {
          font-size: 14px;
        }

        &__col-4 {
          flex: 0 0 214px;
        }

        &__col-3 {
          flex: 0 0 150px;
        }

        &__col-2 {
          flex: 0 0 100px;
        }

        &__col-1 {
          flex: 0 0 68px;
        }

        .detailed {
          width: 100%;
          overflow-x: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 991.8px) {
  .admin__resume-list {
    .wrap-table {
      .table {
        &__col-3 {
          flex: 0 0 174px;

          &.address,
          &.position {
            display: none;
          }
        }

        &__col-2 {
          flex: 0 0 100px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767.8px) {
  .admin__section {

    .title-section {
      padding: 20px 0;
    }

    .form {
      width: 100%;
      padding: 30px 20px;

      input {
        height: 40px;
      }
    }
  }

  .admin__resume-list {
    .search-box {
      margin-bottom: 20px;

      &__title {
        padding: 12px 0;
        font-size: 16px;
      }

      &__form {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 18px 14px;
      }

      &__row {
        margin-bottom: 0;
        width: 36%;

        &:last-of-type {
          width: 24%;
        }
      }
    }

    .wrap-table {
      min-height: inherit;
      height: auto;

      .table {
        &__row {
          padding: 12px 0;

          &.header {
            font-size: 16px;
            padding: 14px 0;
          }
        }

        &__col-4 {
          flex: 1 0 26%;
        }

        &__col-3 {
          flex: 1 0 18%;

          &.position {
            display: block;
          }
        }

        &__col-2 {
          flex: 0 0 90px;
        }
      }
    }
  }
}

@media only screen and (max-width: 568px) {
  .admin__resume-list {
    .wrap-table {
      .table {
        &__col-3 {
          &.position {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 440px) {
  .admin__resume-list {
    .search-box {

      &__form {
        display: block;
      }

      &__row {
        margin-bottom: 10px;
        width: 100%;

        &:last-of-type {
          width: 100%;
        }
      }
    }

    .wrap-table {
      .table {
        &__col-3 {
          &.position {
            display: none;
          }
        }

        &__col-2 {
          &.date {
            display: none;
          }
        }
      }
    }
  }
}

@import '../layout/extends';

.flex {
  display: flex;
}

.editor-page {
  position: relative;
}

.editor {
  &__bg {
    background: url(../../images/editor/bg-oval-1.svg), url(../../images/editor/bg-oval-2.svg);
    background-repeat: no-repeat;
    background-position: top 20px right -14px, bottom 50px left -30px;
    background-size: 122px, 216px;
  }

  [contenteditable='true']:empty:before {
    content: attr(placeholder);
    display: block;
    color: #a0a0a0;
  }

  div.simulate-contentEditable:empty:before {
    content: attr(placeholder);
    display: block;
    color: #a0a0a0;
    cursor: text;
  }

  .config {
    padding: 20px;
  }

  .preview {
    display: flex;
    justify-content: flex-end;
    z-index: 5;
    min-height: 100vh;

    > .wrapper {
      display: inline-block;
      padding: 20px;
    }

    .loading {
      text-align: center;
      margin: 100px auto;
    }
  }
}

.draggable {
  @extend %main-section;

  &.main-section.item {
    color: #333;
    line-height: 1.6;
    font-size: 13px;
    position: relative;
    display: flex;
    list-style: none;
    border: 1px dashed #dddddd;
    padding: 5px;

    .delete {
      display: none;
    }

    .date {
      padding-bottom: 5px;

      > div {
        display: inline-block;
      }
    }

    [contenteditable='true']:empty:before {
      content: attr(placeholder);
      display: block;
      color: #a0a0a0;
    }
  }
}

.palette {
  display: flex;
}

@import '../layout/templates/main';
@import '../layout/toolbar';

@media (max-width: 1199.8px) {
  .editor {
    .config {
      padding: 0 10px;
      height: 70px;
    }
    .preview {
      margin-top: 30px;
      justify-content: center;
      > .wrapper {
        overflow-x: auto;
      }
    }
  }
}

@media (max-width: 991.8px) {
  .editor {
    .container {
      max-width: 100%;
    }
  }
}
@media (max-width: 450px) {
  .editor {
    .preview {
      margin: 0;
    }
  }
}

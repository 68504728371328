$color_1: #666;
$color_2: #969696;
$color_3: #4d89ff;
$font_family_1: 'Georgia', serif;
$font_family_2: 'Helvetica', sans-serif;
$background_color_1: rgba(0, 0, 0, 0.05);

.rich-editor {
  background: #fff;
  border: 1px solid #ececec;
  @include border-radius(5px);
  font-family: $font_family_1;
  font-size: 14px;
  padding: 10px;

  .style-button {
    color: $color_2;
    cursor: pointer;
    display: inline-block;
    padding: 10px;
  }

  .active-button {
    color: $color_3;
  }

  > .editor {
    border-top: 1px solid #ececec;
    cursor: text;
    font-size: 14px;
    margin-top: 5px;

    .public-DraftEditorPlaceholder-root {
      margin: 0 -15px -15px;
      padding: 15px;
    }

    .public-DraftEditor-content {
      margin: 0 -15px -15px;
      padding: 10px 25px;
      min-height: 100px;
    }

    .rich-editor-controls {
      font-family: $font_family_2;
      font-size: 14px;
      margin-bottom: 5px;
      user-select: none;
    }
  }
}



.what-next {
  min-height: calc(100vh - 266px);
  padding-bottom: 20px;

  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 70px;
    color: #000000;
    padding: 60px 0 16px 0;
    font-family: 'Raleway', sans-serif;
  }

  .sub-title {
    text-align: center;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    color: #000000;
    padding-bottom: 20px;
  }

  .left-arrow {
    width: 24px;
    margin-right: 8px;
  }
  
  .buyMeACoffee {
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    .buyMeACoffeeImage {
      margin-top: 10px;
      height: 60px;
      width: 217px;
    }
  }

  .btn-back {
    height: 48px;
    display: inline-flex;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
    border: 2px solid #8BAEED;
    border-radius: 32px;
    background: #F5F7FA;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #8BAEED;
  }

  &__separator {
    font-size: 14px;
    text-align: center;
    margin: 47px 0 0 0;
    color: #828484;
  }

  .section__title {
    text-align: center;
    margin-bottom: 37px;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
  }

  .share {
    width: 170px;

    &__items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 170px;
      margin: 0 auto;
    }

    &__item {
      cursor: pointer;
      border-radius: 6px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      @include transition(0.5s ease);

      &:hover {
        transform: scale(1.1);
      }

      &--facebook {
        &:hover {
          background-color: #425ea9;
        }
        background-color: #4267b2;
      }
      &--linkedin {
        &:hover {
          background-color: #006eac;
        }
        background-color: #0077b5;
      }
      &--twitter {
        &:hover {
          background-color: #0086d0;
        }
        background-color: #00a4f0;
      }
    }
  }
}

@media only screen and (max-width: 1023.8px) {
  .what-next {
    &__separator {
      margin: 10px 0;
      visibility: hidden;
    }

    .buy-coffee {
      display: block;

      &__btn {
        float: unset;
        margin: 0 auto;
      }

      .widget {
        margin: 0 auto 30px auto;
        display: block;
        left: unset;
        bottom: unset;
        position: relative;
      }
    }
  }
}
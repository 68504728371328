@import '../helpers/variables';
@import '../helpers/mixins';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

ol,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}

input,
button,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input {
  box-shadow: none;
  @include border-radius(0);
}

a {
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
  @include transition(color 0.4s ease);
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

img,
iframe {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $second-font-family;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

b, strong {
  font-weight: bold;
}
.jobs {
  .blockquote {
    margin: 30px 0 20px 0;
    background-color: #fcf3f0;
    width: 100%;
    padding: 45px 65px;
    font-weight: 600;
  }

  .highlighted {
    background-color: #cfd6e6;
    padding: 0 5px;
  }

  &__header {
    background: url(../../images/jobs/background.svg) no-repeat top center;
    padding: 20px 0 180px;
  }

  &__header__title {
    font-size: 38px;
    line-height: normal;
    margin: 125px 0 20px;
    text-transform: uppercase;

    span {
      display: block;
      font-weight: 500;
    }
  }

  &__header__template {
    width: 100%;
    margin: 0 auto;
  }

  &__section-title {
    font-family: "Raleway", sans-serif;
    font-size: 30px;
    line-height: 1.33;
    color: #333;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;

    .highlighted {
      padding: 5px 10px;
    }

    span {
      font-weight: 700;
    }
  }

  &__links {
    padding-bottom: 140px;

    .jobs__section-title {
      &::after {
        content: "";
        position: absolute;
        right: -20px;
        top: 70%;
        width: 250px;
        height: 105px;
        background: url("../../images/jobs/arrows/1-arrow.svg") no-repeat center
          center/cover;
      }
    }

    .btn-border-orange {
      margin: 0 20px 20px 0;
    }
  }

  &__fact {
    .talk-about {
      font-size: 16px;
    }

    .jobs__section-text {
      padding-top: 20px;
    }

    .jobs__section-title {
      text-align: center;
      padding-bottom: 15px;
    }

    &-girl-illustration {
      background: url(../../images/jobs/girl_illustration.svg) no-repeat top 60px
        right 0;
      height: 700px;
      background-size: 90%;
    }

    &-bg {
      background: url(../../images/jobs/fact-background.svg) no-repeat top 120px
        left 200px;
    }
  }

  &__consist {
    margin-top: 90px;

    .jobs__section-title {
      margin-top: 20px;
    }

    .arrow {
      display: block;
      position: absolute;
      left: 20%;
      top: 90%;
      width: 270px;
      height: 210px;
      background: url("../../images/jobs/arrows/2-arrow.svg") no-repeat center
        center/cover;
    }

    &-item {
      margin-top: 40px;
      position: relative;

      h3 {
        font-size: 24px;
      }

      .jobs__section-text {
        p {
          margin-top: 20px;
        }
      }

      .item-image {
        width: 65%;
        margin: 0 auto;
        user-select: none;
      }

      &--experience {
        .item-image {
          padding-top: 130px;
        }
      }

      &--education {
        .bg {
          background: url(../../images/jobs/consist-item-2-bg.svg) no-repeat center
            center;
          height: 700px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        }

        .item-image {
          padding-top: 220px;
        }
      }

      &--profile,
      & {
        .item-image {
          margin-top: 0;
        }
      }
    }
  }

  &__tips {
    padding-top: 100px;
    background: url(../../images/jobs/tips-bg.svg) no-repeat top center;

    .tips-action-block {
      position: relative;
      height: 260px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url(../../images/jobs/ready-to-create-bg.svg) no-repeat center
        center;
      margin-bottom: 110px;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 120%;
        transform: translateX(-80%);
        width: 545px;
        height: 105px;
        background: url("../../images/jobs/advise.svg") no-repeat center center;
      }

      .creat-resume__btn {
        margin-top: 20px;
      }
    }

    .ready-to-create {
      padding-left: 120px;
      font-weight: 500;
      font-size: 30px;
      color: #ffffff;
      text-transform: uppercase;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        right: -15%;
        top: 16%;
        width: 386px;
        height: 200px;
        background: url("../../images/jobs/arrows/3-arrow.svg") no-repeat center
          center/cover;
      }
    }

    .it-free {
      padding-left: 15px;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
    }

    .tips-other {
      .question {
        font-size: 30px;
        font-weight: 500;
        padding-top: 70px;
      }

      .list-title {
        padding-top: 30px;
      }
    }

    .tips-list {
      position: relative;
      margin-top: 70px;
      &::before {
        content: " ";
        background-color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 84%;
        border-radius: 25px 0 0 25px;
      }
      .paper-wrapper {
        margin: 0 0 20px 130px;
        padding-bottom: 30px;

        .list {
          width: 85%;
          position: relative;
          padding: 55px 0 0 140px;

          li {
            margin-top: 30px;
          }
        }

        .title {
          display: flex;
          align-items: center;
          position: relative;
          font-weight: 700;
          font-size: 16px;
          text-transform: uppercase;

          &::before {
            content: " ";
            background: url(../../images/jobs/tips-check.svg) no-repeat top center;
            position: absolute;
            left: -55px;
            width: 40px;
            height: 40px;
          }
        }

        .description {
          padding-top: 10px;
          font-size: 16px;
        }
      }
    }
    .tips-under {
      margin: 60px 0;
    }
  }

  &__bottom-block {
    padding: 40px 0;

    .action-block {
      &__right-col {
        position: relative;
        .for-free {
          display: block;
          width: 500px;
          height: 80px;
          background: url("../../images/jobs/for-free.svg") no-repeat center
            center/cover;
        }

        .creat-resume__btn {
          margin-left: 17%;
          margin-top: -10px;
        }
      }
      .sub-message {
        font-size: 16px;
        margin-bottom: 25px;
      }
      .main-message {
        font-weight: 500;
        font-size: 30px;
        color: #3d3d3d;
        text-transform: uppercase;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          right: 20px;
          top: -70%;
          width: 180px;
          height: 360px;
          background: url("../../images/jobs/arrows/4-arrow.svg") no-repeat center
            center/cover;
          transform: rotate(70deg);
        }
      }
    }
  }
}

@media only screen and (max-width: 1199.8px) {
  .jobs__section-title {
    font-size: 20px;
    line-height: 1.25;
  }

  .jobs__header__title {
    font-size: 25px;
  }

  .jobs .blockquote {
    padding: 30px 40px;
  }

  .jobs__consist-item h3 {
    font-size: 18px;
  }

  .jobs__consist .arrow {
    left: 17%;
    top: 70%;
    width: 230px;
    height: 170px;
  }

  .jobs__tips {
    padding-top: 60px;
  }

  .jobs__tips .tips-action-block {
    background-size: 960px;
    margin-bottom: 80px;
  }

  .jobs__tips .tips-action-block::after {
    top: 100%;
    width: 460px;
    height: 70px;
    background-size: contain;
  }

  .jobs__tips .tips-other .question {
    font-size: 25px;
    padding: 0;
  }

  .jobs__tips .ready-to-create {
    padding-left: 80px;
    font-size: 20px;
  }

  .jobs__tips .ready-to-create::after {
    right: -10%;
    top: 15%;
    width: 300px;
    height: 150px;
  }

  .jobs__bottom-block .action-block .main-message {
    font-size: 20px;
  }

  .jobs__bottom-block .action-block__right-col .for-free {
    width: 100%;
    background-size: contain;
  }

  .jobs__bottom-block .action-block .main-message::after {
    width: 130px;
    height: 270px;
  }
}

@media only screen and (max-width: 991.8px) {
  .jobs__header {
    padding-bottom: 80px;
    background-size: 1400px;
  }

  .jobs__header__title {
    margin-top: 50px;
  }

  .jobs__links {
    padding-bottom: 100px;
  }

  .jobs__links .jobs__section-title::after {
    width: 140px;
    height: 50px;
  }

  .jobs__links .btn-border-orange {
    height: 34px;
  }

  .jobs__consist .arrow {
    top: 100%;
    width: 170px;
    height: 120px;
  }

  .jobs__consist-item .jobs__section-text p {
    margin-top: 10px;
  }

  .jobs__tips {
    padding-top: 30px;

    .tips-action-block {
      background-size: 770px;
      margin-bottom: 60px;
      .creat-resume__btn {
        margin-top: 10px;
      }
    }

    .ready-to-create {
      padding: 0;
      text-align: center;
      font-size: 19px;
      &::after {
        width: 200px;
      }
    }

    .it-free {
      font-size: 14px;
    }

    .tips-list {
      margin-top: 30px;
      &::before {
        width: 90%;
      }
      .paper-wrapper {
        margin: 20px 0 20px 45px;

        .title {
          font-size: 14px;
          &::before {
            width: 30px;
            height: 30px;
            background-size: contain;
            left: -40px;
          }
        }

        .list {
          width: 100%;
          padding: 0 0 0 90px;
        }
      }
    }
  }

  .jobs__bottom-block .action-block .main-message::after {
    width: 94px;
    height: 180px;
    right: -16%;
    top: 0;
  }
}

@media only screen and (max-width: 767.8px) {
  .jobs__fact-girl-illustration {
    background: url(../../images/jobs/girl_illustration.svg) no-repeat center;
    height: 370px;
    background-size: auto 100%;
    margin-top: 20px;
  }
  .jobs__consist {
    &-item {
      &--experience {
        .item-image {
          padding: 0 0 20px 0;
        }
      }
      &--education {
        .bg {
          background: url(../../images/jobs/consist-item-2-bg.svg) no-repeat top
            center;
          background-size: 900px;
        }

        .item-image {
          padding: 0 0 20px 0;
        }
      }
    }
  }

  .jobs__header {
    padding: 20px 0 60px;
    p {
      text-align: center;
      font-size: 14px;
    }
    .creat-resume__btn {
      margin: 32px auto;
    }
  }

  .jobs__header__title {
    font-size: 22px;
    margin: 0 0 20px;
    text-align: center;
  }

  .jobs__section-title {
    font-size: 16px;
  }

  .jobs__links {
    padding-bottom: 60px;
    .jobs__section-title {
      text-align: center;
      margin-bottom: 24px;
      &::after {
        right: 0;
        top: 70%;
        width: 60px;
        height: 40px;
        background: url(../../images/jobs/arrows/mob-1-arrow.svg) no-repeat center
          center/cover;
      }
    }
    &_group {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .jobs__fact .jobs__section-title {
    padding: 0;
  }

  .jobs__fact .jobs__section-text {
    padding-top: 10px;
    font-size: 14px;
  }

  .jobs .blockquote {
    margin: 20px 0;
    padding: 16px 24px;
    font-size: 14px;
    text-align: center;
  }

  .jobs__consist {
    margin-top: 50px;
    .arrow {
      display: none;
    }
  }

  .jobs__consist-item h3 {
    font-size: 16px;
  }

  .jobs__consist-item .jobs__section-text p {
    font-size: 14px;
  }

  .jobs__tips {
    .tips-action-block {
      background-size: cover;
      margin-bottom: 40px;
      height: 220px;
      &::after {
        left: auto;
        right: 20px;
        top: 100%;
        width: 260px;
        height: 50px;
        transform: translateX(0);
      }
    }

    .ready-to-create {
      padding-left: 0;
      text-align: center;
      font-size: 16px;
      &::after {
        right: auto;
        left: 10%;
        top: 16%;
        width: 100px;
        height: 100px;
        background: url(../../images/jobs/arrows/mob-2-arrow.svg) no-repeat center
          center/contain;
        transform: rotate(60deg);
      }
    }

    .it-free {
      text-align: center;
      padding: 13px 0 0 0;
      font-size: 14px;
    }

    .tips-other .question {
      font-size: 16px;
    }

    .tips-other .list-title {
      padding-top: 20px;
      font-size: 14px;
    }

    .tips-list {
      &::before{
        width: 95%;
      }
      .paper-wrapper {
        margin: 0;
        .description {
          font-size: 14px;
        }

        .list {
          width: 100%;
          padding: 5px 10px 0 70px;
        }
      }
    }
  }

  .jobs__bottom-block .action-block {
    &__right-col{
      .for-free {
        width: 60%;
        height: 50px;
        margin: -10px 0 20px auto;
      }
      .creat-resume__btn {
        margin: 0 auto;
      }
    }
    .main-message {
      margin: 0 auto;
      text-align: center;
      font-size: 16px;
      &::after {
        width: 90px;
        height: 100px;
        right: auto;
        left: 0;
        top: 70%;
        background: url(../../images/jobs/arrows/mob-3-arrow.svg) no-repeat center
        center/contain;
      }
    }
    .sub-message {
      margin: 0 auto 20px;
      text-align: center;
      font-size: 14px;
      width: 80%;
    }
  }
}

@media only screen and (max-width: 425px) {

  .jobs__links .jobs__section-title {
    >br{
      display: none;
    }
  }

  .jobs__tips .ready-to-create::after {
    left: 0;
  }

  .jobs__bottom-block .action-block .main-message::after {
    left: -20px;
  }

  .jobs__bottom-block .action-block__right-col .for-free{
    width: 75%;
  }
}

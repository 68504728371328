.buy-coffee {
  right: 30px;
  bottom: 30px;
  z-index: 999;
  position: absolute;

  &.position-sticky {
    position: fixed;
  }

  &__btn {
    z-index: 99999;
    float: right;
    clear: both;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    background: rgba(159, 71, 255, 0.65);
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.4) 0 4px 8px;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.2s ease 0s;

    &--active {
      transform: scale(0.8);
    }

    .close-btn {
      position: absolute;
      opacity: 1;

      &:before, &:after {
        position: absolute;
        content: ' ';
        height: 18px;
        margin: -8px -2px;
        width: 2px;
        background-color: #fff;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    &-image {
      width: 32px;
      transition: all 0.2s ease 0s;
    }

    &:hover {
      .buy-coffee__btn-image {
        transform: scale(0.9);
      }
    }
  }


  h3 {
    padding: 20px 0 30px 0;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
  }

  .widget {
    font-family: 'Lato', sans-serif;
    background: #fff;
    position: absolute;
    margin: 0;
    border: 0;
    right: 0;
    bottom: 85px;
    width: 300px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0 8px 16px;
    transition: all 0.4s ease 0s;

    &-container {
      padding: 0 20px 15px;
      border-radius: 10px 10px 0 0;
      border-top: 9px solid rgba(159, 71, 255, 0.65);
    }

    &-field {
      width: 100%;
      color: #1a2a3c;
      background-color: #fff;
      line-height: normal;
      border: 1px solid #e6e9ec;
      margin: 5px 0;
      font-size: 13px;
      padding: 10px;
      border-radius: 6px;

      &::placeholder {
        color: #a0a0a0;
      }

      &.has-error {
        border: 1px solid #ffae98;
      }
    }

    &-fields-group {
      padding: 14px 0;
    }

    textarea {
      height: 80px;
      resize: none;
    }

    .support-btn {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      padding: 14px 0;
      width: 100%;
      align-content: center;
      font-weight: 500;
      color: #fff;
      letter-spacing: 0.5px;
      background-color: rgba(159, 71, 255, 0.65);

      &:hover {
        background-color: rgba(131, 66, 221, 0.65);
      }

      span {
        padding-left: 7px;
      }
    }

    .count-block {
      background: rgba(189, 95, 255, 0.06);
      border: 1px solid rgba(189, 95, 255, 0.264776);
      padding: 25px 5px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .cup {
        position: relative;

        &-group {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 45px;
        }

        &-image {
          width: 32px;
        }

        &-price {
          position: absolute;
          top: 19px;
          color: #000;
          left: 10px;
          font-size: 10px;
          font-weight: 800;
        }

        &-x {
          font-size: 13px;
          color: #c5c5c5;
        }

        &-count {
          color: #616060;
          text-align: center;
          font-size: 12px;
          width: 25px;
          height: 37px;
          border-radius: 3px;
          border: 1px solid #ddd;
        }

        &-count::-webkit-outer-spin-button,
        &-count::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &-count[type=number] {
          -moz-appearance: textfield;
        }
      }

      .choices {
        .choice {
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #fff;
          border: 1px solid rgba(189, 95, 255, 0.264776);
          font-size: 13px;
          color: rgba(159, 71, 255, 0.65);
          transition: all 0.2s ease 0s;
          font-weight: 500;

          &.active, &:hover {
            background-color: rgba(159, 71, 255, 0.65);
            color: #fff;
          }
        }

        .choice + .choice {
          margin-left: 5px;
        }
      }
    }
  }
}
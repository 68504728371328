.template-andromeda {
  color: #464646;
  line-height: 1.6;
  font-family: Tahoma, Verdana, Segoe, sans-serif;

  .page {
    background-color: $base-bg;
    padding: 0;

    > .flex {
      height: 100%;
    }
  }

  .items .item:hover {
    .drag .icon,
    .delete .icon {
      opacity: 1;
    }
  }

  .section:hover .add-item {
    display: block;
    opacity: 0.7;
    z-index: 10;
  }

  .add-item {
    @include transition(0.5s ease);

    position: absolute;
    top: 19px;
    right: 5px;
    font-size: 17px;
    font-weight: 200;
    cursor: pointer;
    padding: 5px;
    opacity: 0;

    &:hover {
      opacity: 1;
    }

    .icon {
      font-size: 12px;
    }
  }

  .items {
    .item {
      position: relative;
      line-height: 1.8;
    }

    .item {
      margin-bottom: 15px;
    }

    .drag {
      height: 100%;
      width: 20px;
      position: absolute;
      display: flex;
      left: -20px;
      top: 0;

      .icon {
        @include transition(0.5s ease);
        position: absolute;
        align-self: center;
        display: block;
        width: 10px;
        height: 10px;
        cursor: row-resize;
        color: #6a6a6a;
        font-size: 14px;
        opacity: 0.05;
      }
    }

    .delete {
      cursor: pointer;
      position: absolute;
      right: -17px;
      top: 0;
      display: flex;
      height: 100%;
      width: 25px;

      .icon {
        @include transition(0.5s ease);
        margin: 0 auto;
        align-self: center;
        opacity: 0;
        color: #e00703;
        font-size: 12px;
      }
    }
  }

  .right {
    margin-right: 25px;
    width: 100%;
    border-left: 1px solid;
    padding-left: 13px;

    .add-item {
      top: 20px;
      right: -23px;
    }

    .item {
      .drag {
        left: -30px;
      }

      .delete {
        right: -25px;
      }
    }
  }

  .page.first-page {
    .right {
      .section {
        &:first-child {
          .title {
            padding-top: 0;
          }
        }
      }
    }
  }

  .left {
    display: inline-block;
    margin: 0 40px 0 0;
    flex: 0 0 260px;
    word-break: break-word;

    .items {
      padding-left: 3px;
    }

    .section {
      padding: 0 20px;
    }

    .section.without_padding {
      padding: 0;
    }
  }

  .default-section {
    .items {
      .item {
        &:first-child {
          .wrapper {
            padding-top: 0;
          }
        }

        .date {
          > div {
            display: inline-block;
          }
        }
      }
    }

    &.experience {
      .position {
        font-weight: 600;
        font-size: 1.1em;
        float: left;
      }

      .date {
        float: right;
        padding: 0 5px;
        border-radius: 2px;
      }

      .company {
        float: left;
        font-size: 1.1em;
      }

      .location {
        float: right;
      }

      .description {
        padding-top: 10px;
        padding-bottom: 5px;

        .gap {
          line-height: 0.7;
        }
      }
    }
  }

  .header {
    > .wrapper {
      padding: 30px 40px;
      height: 100%;

      .name {
        text-transform: uppercase;
        font-size: 3.2em;
        letter-spacing: 0.05em;
        line-height: 1em;
      }

      .position {
        text-transform: uppercase;
        position: relative;
        background: #fff;
        margin: 10px 0;
        font-size: 1.4em;
        letter-spacing: 0.03em;

        .wrapper {
          position: relative;
          background: #fff;
          z-index: 10;
          padding-right: 10px;
          display: inline-block;
        }
      }

      .line {
        border-bottom: 1px solid;
        position: absolute;
        width: 100%;
        bottom: 0.3em;
        z-index: 5;
      }
    }
  }

  .section.logo {
    > .wrapper {
      width: 260px;
      line-height: 0;

      &.no-photo {
        height: 260px;
      }

      > div {
        padding: 15px 15px 15px 0;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      img {
        width: 100%;
      }

      .file-btn {
        @extend %logo-hover;

        .icon.icon-camera {
          top: 40%;
        }
      }
    }
  }

  .language {
    .items {
      .item {
        margin-bottom: 8px;
      }
    }
  }

  .skills {
    .items {
      .item {
        margin: 0 10px 0 0;
        display: inline-block;

        .wrapper {
          display: flex;
          align-items: center;
          justify-content: center;

          .skill {
            padding: 3px;
          }

          .dot {
            cursor: move;
            padding: 8px 2px 8px 0;
            display: block;

            &.black {
              &:before {
                background-color: #464646;
              }
            }

            &.white {
              &:before {
                background-color: #e0e0e0;
              }
            }

            &:before {
              content: '';
              width: 6px;
              border-radius: 50%;
              height: 6px;
              display: block;
            }
          }
        }
      }
    }
  }

  .contacts {
    padding-top: 20px;

    .icon {
      min-width: 30px;
      font-size: 16px;
      display: inline-block;
      vertical-align: middle;
    }

    .value {
      vertical-align: middle;
      font-size: 0.9em;
    }

    .items > div {
      padding: 5px 0;
      display: flex;
      align-items: center;
    }
  }

  .education {
    position: relative;

    .field-of-study {
      float: left;
      font-weight: 600;
      font-size: 1.1em;
    }

    .date {
      float: right;
      padding: 0 5px;
      border-radius: 2px;
    }
  }

  .section {
    position: relative;

    .title {
      font-size: 1.3em;
      letter-spacing: 0.05em;
      padding-bottom: 10px;
      padding-top: 25px;
    }
  }
}

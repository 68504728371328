%main-section {
  display: flex;
  position: relative;

  .title {
    flex: 0 0 130px;
    margin-right: 30px;
    word-break: break-all;
  }

  .items:hover .add-item {
    display: block;
    opacity: 0.7;
  }

  .add-item {
    @include transition(0.5s ease);

    position: absolute;
    top: -48px;
    right: -24px;
    font-size: 19px;
    font-weight: 200;
    cursor: pointer;
    padding: 5px;
    color: #8caeed;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }

  .items {
    width: 100%;

    .sortable-chosen {
      //border: 1px solid #ccc;
      background: #fff;
      padding: 5px;
      box-shadow: 0 0 10px 0 rgba(199, 207, 225, 0.79);


      &.item {
        .delete {
          display: none;
        }
      }
    }

    .item {
      .delete {
        cursor: pointer;
        position: absolute;
        right: -35px;
        top: 0;
        display: flex;
        height: 80%;
        width: 25px;

        .icon {
          @include transition(0.5s ease);
          margin: -10px auto 0 auto;
          align-self: center;
          opacity: 0;
          color: #e00703;
          font-size: 14px;

          &:hover {
            opacity: 1;
          }
        }
      }

      position: relative;
      list-style: none;

      > .add-item {
        padding: 10px;
      }

      &:hover .delete .icon {
        opacity: 0.5;
      }

      &:hover .drag .icon {
        opacity: 1;
      }

      .drag {
        height: 100%;
        width: 35px;
        position: absolute;
        display: flex;
        left: -35px;
        top: 0;

        .icon {
          @include transition(0.5s ease);
          position: absolute;
          right: 20px;
          align-self: center;
          display: block;
          width: 12px;
          height: 12px;
          cursor: row-resize;
          color: #bfbfbf;
          font-size: 14px;
          opacity: 0.25;
        }
      }
    }
  }
}

.modal-photo {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 105;

  .wrapper {
    padding: 20px 50px;
    border-radius: 7px;
    min-width: 600px;
    max-width: 1000px;
    background-color: #e6e6e6;

    .actions {
      padding-top: 20px;
      text-align: center;

      button {
        display: inline-block;
        margin-right: 10px;
      }
    }

    .photo {
      &.round-photo {
        .cropper-view-box,
        .cropper-face {
          border-radius: 50%;
        }
      }
    }

    .photo {
      img {
        margin: 0 auto;
        height: 400px;
      }
    }
  }
}

.modal-pdf-preview {
  background-color: #ddd;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 105;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 36px;

  > div {
    > .close-modal-pdf {
      position: fixed;
      top: 45px;
      right: 45px;
      width: 24px;
      height: 24px;
      padding: 0;
      z-index: 100;

      &:hover::after,
      &:hover::before {
        transition: all 0.3s ease 0s;
        background-color: #6e6e6e;
        box-shadow: 0 0 0 0 rgba(202, 202, 202, 0.45);
      }

      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 11px;
        right: 0;
        width: 100%;
        height: 3.4px;
        background-color: #868686;
        box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.45);
        @include border-radius(6px);
        cursor: pointer;
      }

      &::after {
        @include transform(rotate(-45deg));
      }

      &::before {
        @include transform(rotate(45deg));
      }
    }

    > .wrapper {
      width: 100%;
      padding: 20px;
      overflow-x: auto;
      margin-bottom: 30px;
    }
  }

  .template {
    // TODO: move and extend
    .page {
      @include border-radius(unset);
      background: #fff;
      margin-bottom: 30px;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
      &:last-of-type {
        margin-bottom: 0;
      }

      .default-section {
        .items {
          .add-item {
            display: none;
          }

          .item {
            .drag,
            .delete {
              display: none;
            }
          }
        }
      }

      .editable {
        &:focus,
        &:hover {
          background: none;
        }
      }
    }

    .page-number {
      display: none;
    }
  }
}

@media only screen and(max-width: 970px) {
  .modal-pdf-preview {
    display: block;
    padding: 40px 40px 10px;

    > div {
      > .close-modal-pdf {
        top: 22px;
        right: 14px;
        width: 20px;
        height: 20px;

        &::after,
        &::before {
          height: 3px;
        }
      }
      > .wrapper {
        padding: 0;
      }
    }
  }
  .template {
    .page {
      box-shadow: none;
    }
  }
}

@media only screen and(max-width: 767.8px) {
  .modal-pdf-preview {
    padding: 40px 15px 10px;
    > div {
      > .close-modal-pdf {
        top: 12px;
        right: 6px;
      }
    }
  }
}

.contact-us {
  min-height: calc(100vh - 266px);
  padding-bottom: 20px;
  .title-section {
    text-align: center;
    margin: 30px 0;
  }

  .description {
    padding-bottom: 30px;
    font-size: 16px;
    text-align: center;
  }

  .success-message {
    text-align: center;
    padding: 15px 0 100px 0;
    color: $green;
  }

  .form {
    width: 600px;
    position: relative;
    margin: 0 auto;
    padding: 50px;
    border: 1px solid $border-form;
    border-radius: 21px;
    background: $base-bg;

    .main-error {
      text-align: center;
      color: $color-error;
      display: block;
      font-size: 14px;
      margin: 0 auto 30px;
    }

    .form-row {
      margin-bottom: 14px;

      .error {
        color: $color-error;
        font-size: 14px;
        margin: 5px 0 0 20px;
      }
    }

    input,
    textarea {
      &:focus {
        border: 1px solid $border-focus;
      }
      &::placeholder {
        color: $color-placeholder;
      }
      &.has-error {
        border: 1px solid $color-error;
      }
      width: 100%;
      color: $text-input;
      background-color: $base-bg;
      font-size: 15px;
      line-height: normal;
      border: 1px solid $border-input;
    }

    input {
      height: 45px;
      padding: 0 20px;
      @include border-radius(30px);
    }

    textarea {
      padding: 18px 21px 0;
      font-family: 'Lato', sans-serif;
      border-radius: 20px;
      @include border-radius(15px);
      resize: none;
    }

    .message {
      height: 150px;
    }

    .btn-orange {
      min-width: 120px;
      margin: 0 auto;
      &:disabled {
        opacity: 0.7;
      }
    }
  }
}

@media (max-width: 767.8px) {
  .contact-us {
    h1 {
      margin: 0 0 26px;
    }

    .form {
      width: 100%;
      padding: 28px 20px;
    }
  }
}

.toolbar {
  position: relative;
  z-index: 10;
  background-color: $base-bg;
  padding: 32px 9px;
  width: 104px;
  text-align: center;
  box-shadow: 0 0 20px $color-shadow;
  @include border-radius(14px);
  &_fixed {
    position: fixed;
    top: 20px;
  }
  &_absolute {
    position: absolute;
    bottom: 20px;
    top: auto;
  }
  &__item {
    position: relative;
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &::before {
      content: '';
      width: 4px;
      height: 36px;
      background-color: $light-blue;
      position: absolute;
      top: 50%;
      left: -9px;
      @include transform(translateY(-50%));
      box-shadow: 0 2px 4px $color-shadow-blue;
      display: none;
    }
    button,
    a {
      padding: 0;
      color: $text-color;
      font-family: $second-font-family;
      font-size: 10px;
      font-weight: 500;
      line-height: 11px;
      text-transform: uppercase;
      display: inline-block;
      > span {
        opacity: 0.7;
      }
    }
    img {
      display: block;
      margin: 0 auto 10px;
    }
    code {
      font-family: $second-font-family;
    }
    .circle {
      display: block;
      width: 20px;
      height: 20px;
      @include border-radius(50%);
      background-color: $orange-light;
      margin: 0 auto 10px;
    }
    .loading {
      padding: 8px 0;
    }
    &.active {
      &::before {
        display: block;
      }
      .circle {
        background-color: $orange-light-active;
      }
      button,
      a {
        > span {
          opacity: 1;
        }
      }
    }
  }
}

%card {
  position: absolute;
  top: -10px;
  left: 120px;
  @include border-radius(10px);
  background-color: $base-bg;
  box-shadow: 0 0 10px 0 $color-shadow-blue;
  font-family: $base-font-family;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  text-align: left;
}

.choose-font {
  @extend %card;
  padding: 22px 24px;
  width: 162px;
  color: #333333;
  > li {
    cursor: pointer;
    margin-bottom: 16px;

    &.active {
      color: #8caeed;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.choose-template {
  @extend %card;
  padding: 15px 15px;
  width: 150px;
  color: #333333;
  top: -240px;

  > li {
    cursor: pointer;
    margin-bottom: 16px;

    img {
      opacity: 0.5;
    }

    &.active {
      img {
        opacity: 1;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

  %circle {
  display: block;
  @include border-radius(50%);
  border: 2px solid $white;
}

.choose-color {
  @extend %card;
  padding: 34px 29px;
  width: 100px;
  top: -32px;
  &__group {
    position: relative;
    width: 42px;
    height: 42px;
    margin-bottom: 44px;
    cursor: pointer;
  }
  &__first {
    @extend %circle;
    width: 35px;
    height: 35px;
  }
  &__second {
    @extend %circle;
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .btn-choose-color {
    width: 42px;
    height: 42px;
    @include border-radius(50%);
    // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    border: 1px solid $light-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      margin: 0;
    }
  }
}

.choose-font-size {
  @extend %card;
  padding: 18px 24px;
  width: 85px;
  text-align: center;
  top: 50%;
  @include transform(translateY(-50%));
  > li {
    font-weight: 600;
    font-family: serif;
    line-height: normal;
    margin-bottom: 12px;
    cursor: pointer;
    color: $text-color-opacity;
    &:last-of-type {
      margin-bottom: 0;
    }
    &.active {
      color: $light-blue;
    }
    &.large {
      font-size: 45px;
    }
    &.medium {
      font-size: 30px;
    }
    &.small {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.choose-section {
  @extend %card;
  padding: 24px;
  top: 50%;
  background: #fff;
  width: 165px;
  @include transform(translateY(-60%));
  &__title {
    color: $light-blue;
    font-size: 17px;
    line-height: 17px;
    padding-bottom: 6px;
    border-bottom: 2px solid $light-gray;
    margin-bottom: 24px;
    &:not(:first-of-type) {
      margin-top: 24px;
    }
  }
}

.style-checkbox {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 14px;
  color: $text-color-opacity;
  font-family: $base-font-family;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:last-of-type {
    margin-bottom: 0;
  }
  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked {
      ~ .style-checkbox_checked {
        border: none;
        &::after {
          display: block;
        }
      }
      ~ span {
        color: $light-blue;
      }
    }
  }
  &_checked {
    position: absolute;
    top: 50%;
    left: 0;
    height: 14px;
    width: 14px;
    border: 0.8px solid $text-color-opacity;
    @include border-radius(50%);
    @include transform(translateY(-50%));
    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url(../../images/editor/agree.svg) no-repeat center center/100% 100%;
    }
  }
}

.wrap-color-picker {
  @extend %card;
  background-color: $white;
  padding: 16px;
  top: 60%;
  left: 54px;
  @include transform(translateY(-60%));
  .btn-orange {
    margin: 16px auto 0;
    color: $white;
    height: 27px;
    min-width: 94px;
    display: flex;
    font-size: 10px;
    line-height: 16px;
  }
  .color {
    display: flex;
    margin-bottom: 10px;
    border-left: 3px solid;
    border-radius: 3px;
    border-color: #fff;
    padding-left: 7px;

    &.active {
      border-color: #8caeed;
    }

    .round {
      cursor: pointer;
      width: 25px;
      height: 25px;
      @include border-radius(50%);
    }
    .color__field {
      width: 98px;
      text-align: center;
      margin-left: 7px;
      padding: 0 10px;
      color: #1a2a3c;
      background-color: #fff;
      border-radius: 6px;
      font-size: 13px;
      line-height: normal;
      border: 1px solid #e6e9ec;
    }
  }
}

@media (max-width: 1199.8px) {
  .toolbar {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    width: 440px;
    padding: 12px 20px;
    &_fixed {
      top: 10px;
      left: 50%;
      @include transform(translateX(-50%));
    }
    &__item {
      margin-bottom: 0;
      margin-right: 16px;
      min-width: 30px;
      max-width: 54px;
      &:last-child {
        margin-right: 0;
      }
      &::before {
        width: 100%;
        height: 4px;
        top: -10px;
        left: 0;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .choose-font,
  .choose-color,
  .choose-font-size,
  .choose-template,
  .choose-section,
  .wrap-color-picker {
    top: 62px;
    @include transform(translateY(0));
    left: 0;
    padding: 16px 20px;
  }

  .choose-color {
    display: flex;
    align-items: center;
    width: auto;
    left: -20px;
    &__group {
      margin: 0 20px 0 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .wrap-color-picker {
    top: 42px;
    left: 100px;
  }

  .choose-font-size {
    display: flex;
    align-items: center;
    width: auto;
    padding: 12px 20px;
    @include transform(translateX(-40%));
    li {
      margin-bottom: 0;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .choose-template {
    width: 290px;
    @include transform(translateX(-34%));
    display: flex;
    justify-content: space-between;
    align-items: center;
    >li{
      width: 30%;
      margin: 0;
      >img{
        height: auto;
        width: 100%;
        margin: 0;
      }
    }
  }

  .choose-font {
    @include transform(translateX(-40%));
    > li {
      margin-bottom: 12px;
    }
  }

  .choose-section {
    @include transform(translateX(-40%));
    &__title {
      font-size: 15px;
      line-height: 15px;
      padding-bottom: 4px;
      border-width: 1px;
      margin-bottom: 16px;
    }
  }

  .style-checkbox {
    padding-left: 24px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 14px;
  }
}

@media (max-width: 500px) {
  .toolbar {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .toolbar {
    width: calc(100% - 20px);
    justify-content: space-between;
    &_fixed {
      left: 10px;
      right: 10px;
      @include transform(translateX(0));
    }
    &__item {
      margin-right: 0;
      button {
        span {
          display: none;
        }
      }
      .circle,
      img {
        margin-bottom: 0;
      }
    }
  }
  .choose-font,
  .choose-color,
  .choose-font-size,
  .choose-template,
  .choose-section,
  .wrap-color-picker {
    top: 44px;
  }

  .choose-color {
    left: -14px;
    &__group {
      width: 34px;
      height: 34px;
    }
    &__first {
      width: 30px;
      height: 30px;
    }
    &__second {
      width: 20px;
      height: 20px;
    }
    .btn-choose-color {
      width: 34px;
      height: 34px;
    }
  }

  .wrap-color-picker {
    left: 78px;
  }

  .choose-template {
    @include transform(translateX(-44%));
  }
}

@media (max-width: 370px) {
  .toolbar {
    width: 94%;
  }
}

.media-kit {
  &__section {
    background: url(../../images/bg-media-kit/bg-oval-1.svg), url(../../images/bg-media-kit/bg-oval-2.svg),
      url(../../images/bg-media-kit/bg-oval-1.svg);
    background-repeat: no-repeat;
    background-position: top right -14px, bottom right 20px, center left 40px;
    background-size: 120px, 190px, 140px;
    padding-bottom: 40px;
  }
  &__box {
    background-color: $base-bg;
    padding: 30px;
    @include border-radius(14px);
  }
  article {
    margin-bottom: 20px;
  }
  .title-section {
    text-align: center;
    margin-bottom: 40px;
  }
  p {
    padding-bottom: 16px;
    > b {
      font-weight: 700;
      font-family: $second-font-family, sans-serif;
    }
  }
  h2 {
    font-size: $font-size-h3;
    line-height: $line-height-h3;
    color: $title-color;
    padding-bottom: 16px;
  }

  .list-style {
    padding-left: 20px;
    > li {
      position: relative;
      padding-left: 20px;
      margin-bottom: 12px;
      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 8px;
        height: 8px;
        @include border-radius(50%);
        background-color: $orange;
        opacity: 0.6;
      }
    }
  }

  .btn-orange {
    max-width: 350px;
    margin: 40px auto 0;
  }
}

@media only screen and (max-width: 991.8px) {
  .media-kit {
    h2 {
      font-size: $font-size-h3 - 4px;
      line-height: $line-height-h3 - 4px;
    }
  }
}

@media only screen and (max-width: 767.8px) {
  .media-kit {
    p {
      font-size: 16px;
      padding-bottom: 12px;
    }

    h2 {
      font-size: $font-size-h3 - 6px;
      line-height: $line-height-h3 - 6px;
      padding-bottom: 12px;
    }

    .title-section {
      margin-bottom: 20px;
    }

    .btn-orange {
      margin: 30px auto 0;
    }
  }
}

@media only screen and (max-width: 567.8px) {
  .media-kit {
    .header {
      padding: 20px;
    }
    main {
      padding-top: 90px;
    }
    &__section {
      background: transparent;
      padding-bottom: 20px;
    }
    &__box {
      padding: 24px 16px;
    }
    .list-style {
      padding-left: 0;
    }
  }
}

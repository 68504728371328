@font-face {
  font-family: 'FuturaBT';
  src: url('../../fonts/futura/futura-light-bt.ttf');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'FuturaBT';
  src: url('../../fonts/futura/futura-bold-bt.ttf');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon/icomoon.eot?v3r6hs');
  src:  url('../../fonts/icomoon/icomoon.eot?v3r6hs#iefix') format('embedded-opentype'),
  url('../../fonts/icomoon/icomoon.ttf') format('truetype'),
  url('../../fonts/icomoon/icomoon.woff?v3r6hs') format('woff'),
  url('../../fonts/icomoon/icomoon.svg?v3r6hs#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Raleway;
  src: url('../../fonts/Raleway/Raleway-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Raleway;
  src: url('../../fonts/Raleway/Raleway-SemiBold.ttf');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Raleway;
  src: url('../../fonts/Raleway/Raleway-Bold.ttf');
  font-weight: 700;
  font-display: swap;
}
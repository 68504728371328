@import "../../base/reset";
@import "elegant";
@import "andromeda";
@import "simple";

.flex {
  display: flex;
}

.template {
  position: relative;
  margin: 0 auto;

  .ssr {
    &.page {
      @include border-radius(unset);
      box-shadow: unset;

      .add-item {
        display: none;
      }

      .drag {
        display: none;
      }

      .editable {
        &:focus,
        &:hover {
          background: none;
        }
      }
    }
  }

  .page {
    @include border-radius(10px);
    height: 1245px;
    width: 880px;
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(199, 207, 225, 0.79);
  }

  .clearfix::after {
    content: '';
    clear: both;
    display: table;
  }

  .font-size {
    font-size: 1em;
  }

  .editable {
    @include transition(0.5s ease);

    &.inline {
      display: inline-block;
    }

    &:focus {
      outline: none;
      background-color: #eaeaea;
      @include border-radius(5px);
    }

    &:hover {
      background-color: #efefef;
      @include border-radius(5px);
    }
  }
}

.text-editor,
.simulate-contentEditable {
  ul {
    list-style-type: disc;
    padding-left: 25px;
  }

  ol {
    list-style-type: decimal;
    padding-left: 20px;
  }
}

.page-number {
  text-align: center;
  font-weight: 100;
  font-size: 17px;
  padding: 25px 0;
  color: #5d5d5d;
}

%logo-hover {
  @include transition(0.5s ease);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  font-family: $second-font-family, sans-serif;
  color: #8caeed;
  text-transform: uppercase;
  text-align: center;
  z-index: 2;
  opacity: 0.7;

  &.has__photo {
    opacity: 0;
  }

  &:hover {
    display: inline-block;
    background-color: #696969;
    opacity: 0.7;
    color: #fff;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.has__photo::before {
    opacity: 0.7;
    background-color: #777;
  }

  div {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 72px;
    margin-left: -36px;
  }

  .icon {
    top: 29%;
    font-size: 16px;
  }

  input[type='file'] {
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    cursor: pointer;
    color: transparent;
  }
}

$base-bg: #fff;
$base-font-family: 'FuturaBT', sans-serif;
$second-font-family: 'Raleway', sans-serif;
$base-font-size: 18px;
$base-line-height: 1.44;

// title size
$font-size-h1: 38px;
$line-height-h1: normal;

$font-size-h2: 30px;
$line-height-h2: 1.33;

$font-size-h3: 24px;
$line-height-h3: 1.25;

// text, title colors
$text-color: #333333;
$text-color-opacity: rgba(53, 53, 53, 0.3);
$text-light-blue: #8caeed;
$text-white: #fff;
$title-color: #333;
$text-black: #000;
$text-input: #1a2a3c;

// colors
$white: #fff;
$black: #333;
$light-blue: #8caeed;
$light-gray: #cfd6e6;
$orange: #ff7235;
$orange-light-active: #f5a623;
$orange-light: #f7c064;
$color-arrows: #d2d2d2;
$color-arrows-hover: #ff6812;
$green: #37ad37;
$color-error: #ff8080;

$border-form: #ececec;
$border-focus: #9cbaef;
$border-input: #e6e9ec;

$color-placeholder: #b4b4b4;

$bg-white-opacity: rgba(255, 255, 255, 0.8);

$color-shadow: #a2aaba;
$shadow-btn-orang: rgba(255, 114, 53, 0.52);
$color-shadow-blue: rgba(199, 207, 225, 0.79);

.modal-error {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 105;

  &__body {
    padding: 20px 30px;
    border-radius: 7px;
    width: 90%;
    max-width: 450px;
    background-color: #fff;
    text-align: center;
    p{
      font-size: 22px;
    }
    .btn-orange {
      width: 100px;
      height: 30px;
      font-size: 13px;
      margin: 30px auto 0;
      min-width: inherit;
    }
  }
}

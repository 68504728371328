/* header */
header {
  padding: 30px 0 58px;
  font-size: 14px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  @include transition(padding 0.5s ease);
  position: fixed;
  &.attached {
    position: absolute;
  }
  &.fixed {
    background-color: $base-bg;
    padding: 20px 0;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.04);
  }
  &.animated {
    animation: 0.6s ease;
    -webkit-animation: 0.6s ease;
  }
  &__btn {
    margin-left: 110px;
  }

  .multi-menu {
    position: relative;
    z-index: 101;
    .menu__item {
      position: relative;
      padding: 0.5rem 0;
      margin: 0;
    }
    &__sub {
      min-width: 166px;
      position: absolute;
      right: 50%;
      top: 100%;
      text-align: center;
      overflow: hidden;
      transform: translateX(50%);
      background-color: $base-bg;
      li {
        padding: 0 10px;
        a {
          color: $text-color;
          width: 100%;
          display: block;
          padding: 0.5rem 0;
        }
      }
    }
  }

  .language {
    position: relative;
    width: 50px;
    z-index: 101;
    &__sub {
      position: absolute;
      left: 0;
      top: 100%;
      text-align: center;
      overflow: hidden;
      li {
        background-color: $base-bg;
        width: 50px;
      }
    }
    li {
      a, button {
        color: $text-color;
        width: 100%;
        display: block;
        padding: 0.5rem 0;
        > img {
          width: 10px;
          margin-left: 6px;
        }
      }
    }
  }
}

@keyframes move {
  0% {
    @include transform(translateY(-100%));
  }
  100% {
    @include transform(translateY(0));
  }
}

.nav {
  &__content {
    display: flex;
    align-items: center;
  }
}

.menu {
  &__item {
    text-transform: uppercase;
    display: inline;
    margin-left: 70px;
    > div {
      display: inline-block;
    }
    a {
      &.active {
        color: #ff7135;
      }

      color: $text-color;
    }
  }
}

@media only screen and (max-width: 1199.8px) {
  header {
    padding: 28px 0 34px;
    font-size: 12px;
    &__btn {
      height: 30px;
      width: 120px;
      font-size: 12px;
      margin-left: 70px;
    }

    .multi-menu {
      &__sub {
        min-width: 150px;
      }
    }

    .language {
      margin-left: 30px;
    }
  }
}

@media only screen and (max-width: 991.8px) {
  header {
    &__btn {
      height: 25px;
      width: 110px;
    }
  }

  .menu__item {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 767.8px) {
  header {
    padding: 24px 0 38px;
    font-size: 14px;
    &.fixed {
      padding: 10px 0;
    }
    &__btn {
      height: 35px;
      width: 130px;
      margin: 26px auto 0;
    }

    .multi-menu {
      .menu__item {
        padding: 0;
        margin: 0;
      }
      &__sub {
        display: block;
        min-width: auto;
        position: static;
        transform: translateX(0);
        margin-top: 8px;
        li {
          padding: 0;
        }
      }
    }

    .language {
      margin: 0 40px 0 0;
    }
  }

  .nav {
    position: relative;
    &__content {
      overflow: hidden;
      display: block;
      align-items: center;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      text-align: center;
      background-color: $base-bg;
      z-index: 100;
      box-shadow: 0 8px 3px rgba(0, 0, 0, 0.04);
      &__inner {
        flex: 0 0 100%;
        padding: 50px;
      }
    }
  }

  /*button menu*/
  %line-btn {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -11px;
    width: 22px;
    height: 2px;
    background-color: $black;
  }
  .menu {
    &__item {
      display: block;
      margin: 0 0 12px;
    }
    &__btn {
      height: 40px;
      width: 22px;
      position: relative;
      cursor: pointer;
      order: 2;
      span {
        @extend %line-btn;
        &::before,
        &::after {
          content: "";
          @extend %line-btn;
          @include transition(transform 0.3s);
        }
        &::before {
          @include transform(translateY(-5px));
        }
        &::after {
          @include transform(translateY(5px));
        }
      }
      &_active {
        span {
          height: 0;
          &::before {
            @include transform(rotate(-42deg));
          }
          &::after {
            @include transform(rotate(42deg));
          }
        }
      }
    }
  }
  /*button menu*/
}

@media only screen and (max-width: 575.8px) {
  header {
    .container {
      padding: 0;
    }
    .nav {
      padding: 0 20px;
    }
  }
  .logo {
    width: 100px;
  }
}
